import React from "react";
import { makeStyles } from "tss-react/mui";
// @mui/icons-material
// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Image from "next/image";

import styles from "../../styles/jss/nextjs-material-kit/pages/landingPageSections/workStyle.js";
import NewsletterForm from "./NewsletterForm";
import Grid from "@mui/material/Grid";
import Link from "next/link";

const useStyles = makeStyles()(styles);

export default function PreFooter() {
    const { classes } = useStyles();

    return (
        <div className={classes.container}>
            <GridContainer style={{paddingBottom: "20px"}}>
                <GridItem
                    container
                    xs={12}
                    sm={12}
                    md={6}
                    className={classes.gridContainerLinks}
                >
                    <Grid item xs={12} sm={12} md={12} className={classes.gridItemLogo}>
                        <Image width={140} height={52} src="/img/faces/white_logo.png" alt="Success at School" className={classes.logo}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className={classes.gridItemLink}>
                        <Link href="/" className={classes.link}>
                            Home
                        </Link>
                    </Grid>
                    {/*<Grid item xs={12} sm={12} md={6} className={classes.gridItemLink}>*/}
                    {/*    <Link href="/page/partners" className={classes.link}>*/}
                    {/*        Partners*/}
                    {/*    </Link>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} sm={12} md={6} className={classes.gridItemLink}>
                        <Link href="/page/aboutus" className={classes.link}>
                            About Us
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className={classes.gridItemLink}>
                        <Link href="/page/faq" className={classes.link}>
                            FAQ
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className={classes.gridItemLink}>
                        <Link href="/page/whoarewe" className={classes.link}>
                            The Team
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className={classes.gridItemLink}>
                        <Link href="/contactus" className={classes.link}>
                            Contact
                        </Link>
                    </Grid>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} style={{padding: 0}}>
                    <NewsletterForm />
                </GridItem>
            </GridContainer>
        </div>
    );
}
