const footerStyle = (theme) => ({
  footerWrapper: {
    backgroundColor: "#FFF",
  },
  footer: {
    marginLeft: "10%",
    padding: "10px",
    fontSize: "12px",
    fontFamily: "var(--font-brandon-grotesque)",
    justifyContent: "center",
    [theme.breakpoints.down('md')]: {
      margin: 0,
      textAlign: "center",
      fontSize: "14px",
    },
  },
  socials: {
    fontSize: "15px",
    marginLeft: "10%",
    [theme.breakpoints.down('md')]: {
      display: "none",
    },
  },
  dividerVertical: {
    [theme.breakpoints.down('md')]: {
      display: "none",
    },
  },
  tclinks: {
    display: "inline-block",
    [theme.breakpoints.down('md')]: {
      display: "block",
    },

    a: {
      textDecoration: "none",
      "&:hover,&:focus": {
        textDecoration: "underline dashed",
      },
    }
  },
});
export default footerStyle;
