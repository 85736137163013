import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @mui/material components
import { styled } from "@mui/material/styles";
import { makeStyles } from 'tss-react/mui';
import Grid from "@mui/material/Grid";

const PREFIX = 'GridContainer';

const classes = {
  grid: `${PREFIX}-grid`
};

// Moved to frontend/styles/scss/core/_custom.scss
// const StyledGrid = styled(Grid)({
//   [`& .${classes.grid}`]: {
//     marginRight: "-15px",
//     marginLeft: "-15px",
//     width: "auto",
//   },
// });

export default function GridContainer(props) {

  const { children, className, ...rest } = props;
  return (
    <Grid container {...rest} className={classes.grid + " MuiGrid-styledGridContainer " + className}>
      {children}
    </Grid>
  );
}

GridContainer.defaultProps = {
  className: "",
};

GridContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
