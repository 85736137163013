import {container, secondaryColor} from "../../../nextjs-material-kit";

const workStyle = (theme) => ({
  section: {
    padding: "70px 0",
  },
  container: {
    ...container(theme),
  },
  gridContainerLinks: {
    margin: "35px 0 0 0",
    paddingBottom: "40px",
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  gridItemLink: {
    [theme.breakpoints.down('md')]: {
      padding: "0 0 10px",
    },
  },
  gridItemLogo: {
    paddingBottom: "30px",
    [theme.breakpoints.down('md')]: {
      padding: "0 0 30px",
    },
  },
  title: {
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 700,
    margin: "30px 0 0",
    textDecoration: "none",
    textAlign: "left",
    color: "#fff",
  },
  description: {
    textAlign: "left",
    marginBottom: "0",
    color: "#fff",
    fontSize: "12pt",
  },
  textCenter: {
    textAlign: "center",
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px",
  },
  logo: {
    width: "140px",
  },
  link: {
    textDecoration: "none",
    color: "#fff !important",
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 700,
    fontSize: "12pt",
    "&:hover,&:focus": {
      textDecoration: "underline dashed",
      color: "#fff",
    },
  },
  subscribeButton: {
    color: "#095a70 !important",
    backgroundColor: "#fff",
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 700,
    height: "35px",
    fontSize: "16px",
    margin: 0,
    padding: 0,
    maxWidth: "110px",
    borderRadius: "6px",
    textTransform: "none",
    [theme.breakpoints.down('md')]: {
      fontSize: "18px",
      height: "42px",
    },
  },
  floatLeft: {
    float: "left",
  },
  input: {
    marginBottom: "10px",
    width: '100%',
  },
  snackbar: {
    padding: "3px 0",
    marginBottom: "8px",
    border: '1px solid yellow'
  },
  newsletterForm: {
    color: "#fff",
    maxWidth: "70%",
    "& .MuiSnackbarContent-root": {
      padding: "3px 0",
      marginBottom: "8px",
    },
    "& input, & .MuiInputBase-formControl, label": {
      color: "#0dcdca !important",
    },
    "& label": {
      fontSize: "13px",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "inherit",
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: "100%",
    },
  },
});

export default workStyle;
