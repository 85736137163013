import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "tss-react/mui";
import Button from "../../components/CustomButtons/Button.js";

import styles from "../../styles/jss/nextjs-material-kit/pages/landingPageSections/workStyle.js";
import { Stack } from "@mui/material";
import { TextField } from "@mui/material";

const useStyles = makeStyles()(styles);

import { useNewsletterSubscribeRequest } from "../../request/api";
import SnackbarContent from "../../components/Snackbar/SnackbarContent";
import Warning from "@mui/icons-material/Warning";
import Done from "@mui/icons-material/Done";

export default function NewsletterForm() {
  const { classes } = useStyles();

  const [subscriber, setSubscriber] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [subscribeSuccessful, setSubscribeSuccessful] = useState(false);
  const [subscribeError, setSubscribeError] = useState(null);

  const { isMutating, trigger } = useNewsletterSubscribeRequest();

  useEffect(() => {
    window.gtag && window.gtag("event", "newsletter_form");
  }, []);

  const handleInputChange = (event) => {
    setSubscriber({
      ...subscriber,
      [event.target.name]: event.target.value,
    });
  };

  const subscribeToNewsletter = (captchaCode) => {
    setSubscribeError(null);
    setIsSubscribing(true);

    window.gtag && window.gtag("event", "newsletter_submit");

    trigger({
      body: subscriber,
    })
      .then(async (response) => {
        if (response.data?.success) {
          setSubscribeSuccessful(true);
        } else {
          setSubscribeError("An unknown error occurred.");
        }
      })
      .catch((error) => {
        setSubscribeError(
          error.response?.data?.error ||
            error.response?.data?.message ||
            error.message
        );
      })
      .finally(() => {
        setIsSubscribing(false);
      });
  };

  function getMessages() {
    if (subscribeError != null) {
      return (
        <SnackbarContent
          message={subscribeError}
          color="warning"
          icon={Warning}
        />
      );
    } else if (subscribeSuccessful) {
      return (
        <SnackbarContent
          message="Now please check your email to subscribe."
          color="success"
        />
      );
    }

    return null;
  }

  return (
    <div className={classes.newsletterForm}>
      <h4 className={classes.title} id="newsletter-form">
        Sign up to our newsletter
      </h4>

      {getMessages() || (
        <p className={classes.description}>
          Get test careers advice and info on apprenticeships and school leaver
          jobs.
        </p>
      )}

      <form onSubmit={subscribeToNewsletter}>
        <Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              variant="standard"
              name="firstName"
              label="Your First Name *"
              onChange={handleInputChange}
              className={classes.input}
            />
            <TextField
              variant="standard"
              name="lastName"
              label="Your Last Name *"
              onChange={handleInputChange}
              className={classes.input}
            />
          </Stack>
          <TextField
            variant="standard"
            name="email"
            label="Your Email *"
            onChange={handleInputChange}
            style={{ marginBottom: "20px", width: "100%" }}
          />

          <Button
            type="submit"
            disabled={isSubscribing}
            className={classes.subscribeButton}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </div>
  );
}
