/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

import {makeStyles} from 'tss-react/mui';

import styles from "../../styles/jss/nextjs-material-kit/components/footerStyle.js";
import {Stack} from "@mui/material";
import Link from "next/link";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faLinkedin, faTwitter, faYoutube} from '@fortawesome/free-brands-svg-icons';

const useStyles = makeStyles()(styles);

export default function Footer(props) {
  const { classes } = useStyles();

  return (
    <footer className={classes.footerWrapper}>
      <Stack direction="row" className={classes.footer}>
        <div>
          <span style={{ color: "#095A70", padding: "0 5px" }}>
            Copyright © {new Date().getFullYear()} Success At School. All Rights
            Reserved
          </span>
          <span className={classes.dividerVertical}>{" | "}</span>
          <div className={classes.tclinks}>
            <Link style={{ padding: "0 5px" }} href="/page/privacy">
              Privacy Policy
            </Link>
            {" | "}
            {" "}
            <Link style={{ padding: "0 5px" }} href="/page/terms">
              Terms & Conditions
            </Link>
          </div>
        </div>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          className={classes.socials}
        >
          <a href="https://www.youtube.com/channel/UChRZlNI4577XtlLibJNeANQ" target="_blank" rel="noopener">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
          <a href="https://www.facebook.com/successatschool.org" target="_blank" rel="noopener">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a href="http://twitter.com/successatschool" target="_blank" rel="noopener">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a href="https://www.linkedin.com/company/success-at-school" target="_blank" rel="noopener">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="https://www.instagram.com/successatschool" target="_blank" rel="noopener">
            <FontAwesomeIcon icon={faInstagram} />
          </a>

        </Stack>
      </Stack>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
