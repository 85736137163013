import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import { styled } from "@mui/material/styles";
import { makeStyles } from 'tss-react/mui';
import Grid from "@mui/material/Grid";

const PREFIX = 'GridItem';

const classes = {
  grid: `${PREFIX}-grid`
};

// Moved to frontend/styles/scss/core/_custom.scss
// const StyledGrid = styled(Grid)({
//   [`&.${classes.grid}`]: {
//     position: "relative",
//     width: "100%",
//     minHeight: "1px",
//     paddingRight: "15px",
//     paddingLeft: "15px",
//     flexBasis: "auto",
//   },
// });

export default function GridItem(props) {

  const { children, className, ...rest } = props;
  return (
    <Grid item {...rest} className={classes.grid + " MuiGrid-styledGridItem " + className}>
      {children}
    </Grid>
  );
}

GridItem.defaultProps = {
  className: "",
};

GridItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
